import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const BaseInput = (props) => {
  const className = classnames("input-wrapper", {
    [`input-wrapper--${props.appearance}`]: props.appearance,
    'input-wrapper--has-error': !!props.errors?.length,
  });
  return (
    <div ref={(container) => props.setRef?.(container)} className={className}>
      {props.input}
      {props.suggestions ? (
        <div className="input-wrapper__suggestions-wrapper">
          <div className="input-wrapper__suggestions">{props.suggestions}</div>
        </div>
      ) : null}
      {props.help && <div className="input-wrapper__help">{props.help}</div>}
      {props.errors &&
      props.errors.map((error) => (
        <div key={error} className="input-wrapper__error">
          {error}
        </div>
      ))}
    </div>
  );
};

BaseInput.propTypes = {
  appearance: PropTypes.oneOf(["default", "invisible", "vertical", "start"]),
};

export default BaseInput;
