import React from "react";
import compose from "recompose/compose";
import useFormInputState from "./useFormInputState";
import { withFormsy } from "formsy-react";
import { branch, withProps } from "recompose";
import getErrorMessages, { defaultRequiredMessage } from "./getErrorMessages";
import getRequiredMessage from "./getRequiredMessage";

const defaultGetValueFromEvent = (event) => event.target.value;
const defaultGetValueFromProps = (props) => props.value;
const defaultGetPropsFromValue = (value) => ({ value: value || "" });

export default ({
  dirtyOnChange = false,
  getValueFromEvent = defaultGetValueFromEvent,
  getValueFromProps = defaultGetValueFromProps,
  getPropsFromValue = defaultGetPropsFromValue,
  requiredMessage = defaultRequiredMessage,
} = {}) =>
  compose(
    branch(
      (props) => {
        return typeof props.onChange !== "function";
      },
      compose(
        withProps((props) => ({
          value: getValueFromProps(props),
          ...(requiredMessage
            ? {
                validationErrors: {
                  required: requiredMessage,
                  ...props.validationErrors,
                },
              }
            : {}),
        })),
        withFormsy,
        withProps((props) => {
          return {
            onChange: props.setValue,
            getErrorMessages: () =>
              getErrorMessages(
                {
                  ...props,
                  getErrorMessages: () => props.errorMessages,
                  isPristine: () => props.isPristine,
                  showRequired: () => props.showRequired,
                },
                requiredMessage,
                false
              ),
            forceDirty: props.isFormSubmitted,
          };
        })
      ),
      compose(
        withProps((props) => ({
          value: getValueFromProps(props),
          showRequired: () => false,
          getErrorMessages: () => props.errors || [],
          forceDirty: false,
        }))
      )
    ),
    (BaseComponent) => (props) => {
      const {
        errorMessage,
        errorMessages,
        getErrorMessages,
        hasValue,
        isFormDisabled,
        isValid: ignoredIsValid,
        isPristine: ignoredIsPristine,
        isFormSubmitted,
        isRequired,
        isValidValue,
        resetValue,
        setValidations,
        setValue,
        validate,
        runValidation,
        detachFromForm,
        attachToForm,
        showRequired,
        showError,
        name,
        value,
        innerRef,
        required,
        validationError,
        validationErrors,
        validations,
        onChange,
        forceDirty,
        ...rest
      } = props;

      const {
        onBlur,
        getErrors,
        isValid,
        isPristine,
        isDirty,
      } = useFormInputState({
        forceDirty: forceDirty,
        requiredMessage: getRequiredMessage(defaultRequiredMessage, props),
        required: required,
        value: value,
        errors: props.getErrorMessages(),
      });

      return (
        <BaseComponent
          {...rest}
          name={name}
          required={required}
          onChange={(event) => {
            if (props["setRef"] !== undefined) {
              props.setRef({
                id: props.id,
                index: props.index,
                setValue: props.setValue,
              });
            }

            onChange(getValueFromEvent(event, props));
            if (dirtyOnChange) {
              onBlur();
            }
          }}
          onBlur={(event) => {
            onBlur();
            typeof props.onBlur === "function" && props.onBlur(event);
          }}
          {...getPropsFromValue(value, props)}
          isValid={isValid}
          isPristine={isPristine}
          isDirty={isDirty}
          getErrors={getErrors}
        />
      );
    }
  );
